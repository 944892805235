import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import Logo from "./Logo";
import MenuMobile from "@components/MenuMobile";
import Social from "@components/Social";
import { Squash as Hamburger } from "hamburger-react";
import { Link } from "gatsby-plugin-intl";

export const HEADER_HEIGHT = 64;

const Header = ({ location }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <header
      css={css`
          position: fixed;
          height: ${HEADER_HEIGHT}px;
          border-bottom: 2px solid black;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: white;
          z-index: 20;
          padding: 0px 24px;
          box-sizing: border-box;
          background-color: white;
          z-index: 200;

          @media (min-width: 992px) {
              padding: 0px 48px;
          }
      `}
    >
      <Link to="/">
        <Logo />
      </Link>
      <div
        css={css`
            @media (max-width: 991px) {
                display: none;
            }
        `}
      >
        <Social />
      </div>
      <div className="flex w-[24px] aspect-square mt-[3px] block lg:hidden"
           css={css`
               button {
                   padding: 0px;
                   margin-top: 3px;
               }

               @media (min-width: 992px) {
                   display: none;
               }
           `}
      >
        <Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} size={24} />
        <MenuMobile isOpen={isMenuOpen} />
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

export default Header;
