import { css } from "@emotion/react";
import React from "react";
import orelche from "@assets/orelche.png";
import { useIntl } from "gatsby-plugin-intl";

const Contacts = () => {

  const intl = useIntl();

  return (
    <div className="flex items-center"
      css={css`
        @media (max-width: 767px) and (max-height: 600px) {
          display: none;
        }
        img {
          max-width: 40px;
          height: 40px;
          min-width: 40px;
          margin-right: 12px;
          margin-left: -8px;
        }

        @media (max-width: 991px) {
          flex-direction: column;

          img {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 24px;
            max-width: 48px;
            height: 48px;
            min-width: 48px;
          }
        }
      `}
    >
      <img src={orelche} alt="Contact" />
      <p
        css={css`
          font-size: 12px !important;
          @media (max-width: 991px) {
            display: none !important;
          }
        `}
        dangerouslySetInnerHTML={{
          __html: `
          HAWLIK GERGINSKI Architekten ZT GmbH | Fichtegasse 9/2 | A-1010 ${ intl.formatMessage({ id: "Wien" }) }<br />
          T +43-1-489 62 66 | <a href="mailto:office@aha-ege.at">office@aha-ege.at</a> | www.aha-ege.at`,
        }}
      />
      <address
        css={css`
          font-size: 12px !important;
          margin: 8px 0px 0px;
          text-align: center;
          @media (min-width: 992px) {
            display: none !important;
          }
        `}
        dangerouslySetInnerHTML={{
          __html: `
          HAWLIK GERGINSKI Architekten ZT GmbH<br />
          Fichtegasse 9 / 2 | 1010 Wien<br />
        +43-1-489 62 66 | <a href="mailto:office@aha-ege.at">office@aha-ege.at</a>`,
        }}
      />
    </div>
  );
};

export default Contacts;
