import React, {
  useEffect,
  forwardRef,
  useRef,
  createRef,
  useContext, useCallback
} from "react";
import { css } from "@emotion/react";
import styled from "styled-components";
import { gsap } from "gsap/all";
import { debounce, cloneDeep, findIndex } from "lodash";
import { BLACK } from "../styles/colors";
import { ResizeContext } from "../providers/ResizeProvider";
import { items, ITEM_WIDTH, getPageData, getPageKey } from "./page-nav-data";
import { Link, useIntl } from "gatsby-plugin-intl";


import {
  TransitionGroup,
  Transition as ReactTransition
} from "react-transition-group";

const H4 = styled.h4`
    font-family: "noah-heavy";
    font-size: 18px;
    text-transform: uppercase;
    color: ${BLACK};
    white-space: pre;
`;

const timeout = 1000;
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0
  },
  entered: {
    transition: `opacity 350ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity 350ms ease-in-out`,
    opacity: 0
  }
};

const MenuItem = forwardRef((props, ref) => {
  const { title, url } = props;
  const intl = useIntl();
  return (
    <div className="pointer-events-none absolute w-screen left-0 top-0 inset-1">
      <Link
        to={url}
        ref={ref}
        className="py-0 px-2.5 border-l-2 border-black w-12 pointer-events-auto block h-full bg-white transition-all ease-linear">
        <div className="absolute top-0 left-0 h-12 flex items-center py-0 px-2.5"
          css={css`
              transform-origin: left top;
              transform: rotate(-90deg) translate3d(-100%, 0, 0);
          `}>
          <H4>{ intl.formatMessage({ id: title }) }</H4>
        </div>
      </Link>
    </div>
  );
});

const Transition = ({ children, location }) => {
  const itemRefs = useRef([]);
  const maxWidthRef = useRef(null);
  const pageKeyRef = useRef(null);
  const prevKeyRef = useRef(null);
  const { addResizeCallback } = useContext(ResizeContext);

  const {
    pageKey,
    isSingleBlogPost,
    isSingleProject,
    isSingleKarriere
  } = getPageData({
    pathname: location.pathname
  });

  pageKeyRef.current = pageKey;

  if (itemRefs.current.length !== items.length) {
    itemRefs.current = Array(items.length)
      .fill()
      .map((_, i) => itemRefs.current[i] || createRef());
  }

  const createTimeline = useCallback(() => {
    const divs = itemRefs.current.map(itemRef => itemRef.current);
    let from = "start";
    const currentKey = getPageKey({ pathname: location.pathname });
    const currentKeyIndex = findIndex(items, { key: currentKey });
    const prevKeyIndex = findIndex(items, { key: prevKeyRef.current });
    const scrollBarAddition =
      typeof window === "object" ? (window.innerWidth >= 1200 ? 16 : 0) : 0;
    maxWidthRef.current =
      typeof window === "object"
        ? window.innerWidth -
        (items.length - 1) * ITEM_WIDTH -
        scrollBarAddition
        : 0;

    if (prevKeyIndex !== -1) {
      if (prevKeyIndex > currentKeyIndex) {
        from = "end";
      }
    }
    // if current is
    const tl = gsap.timeline({ paused: true }).to(divs, 0.55, {
      // from: "center",
      x: index => {
        const CURRENT = findIndex(items, { key: pageKeyRef.current.replace('/', '') });
        const HAS_OFFSET = CURRENT < index;
        const INITIAL_POS = ITEM_WIDTH * index;
        const OFFSET = HAS_OFFSET ? maxWidthRef.current - ITEM_WIDTH : 0;
        return INITIAL_POS + OFFSET;
      },
      stagger: {
        amount: 0.45,
        ease: "power3.easeInOut",
        from: from
      },
      ease: "power3.easeInOut",
      onComplete: () => {
        prevKeyRef.current = getPageKey({ pathname: location.pathname });
      }
    });

    return tl;
  }, [location]);

  const placeItems = useCallback(() => {
    const tl = createTimeline();
    tl.play();
  }, [createTimeline]);


  useEffect(() => {
    const onResize = debounce(placeItems, 100);
    return addResizeCallback(onResize);
  }, [addResizeCallback, placeItems]);

  useEffect(() => {
    placeItems();
  }, [location.pathname, placeItems]);


  return (
    <React.Fragment>
      <nav className="hidden lg:flex fixed top-14 right-0 bottom-0 z-[300] left-0 pointer-events-none">
        <span className="absolute block w-6 h-screen right-0 top-0 bg-white"/>
        {items.map((item, i) => {
          let _item = cloneDeep(item);
          const CURRENT = findIndex(items, { key: pageKeyRef.current });
          if (item.key === "blog" && isSingleBlogPost) {
            _item.title = "ZURÜCK zu Impulsen";
            return (
              <MenuItem
                index={i}
                isBack={true}
                key={item.key}
                ref={itemRefs.current[i]}
                {..._item}
              />
            );
          } else if (item.key === "projekte" && isSingleProject) {
            _item.title = "ZURÜCK zu Projekten";
            return (
              <MenuItem
                index={i}
                isBack={true}
                key={item.key}
                ref={itemRefs.current[i]}
                {..._item}
              />
            );
          } else if (item.key === "karriere" && isSingleKarriere) {
            _item.title = "ZURÜCK zu Karrieren";
            return (
              <MenuItem
                index={i}
                isBack={true}
                key={item.key}
                ref={itemRefs.current[i]}
                {..._item}
              />
            );
          } else {
            return (
              <MenuItem
                key={item.key}
                index={i}
                isCurrent={CURRENT === i}
                ref={itemRefs.current[i]}
                {...item}
              />
            );
          }
        })}
      </nav>
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout
          }}
        >
          {status => {
            return (
              <div
                style={{
                  ...getTransitionStyles[status]
                }}
              >
                <div>{children}</div>
              </div>
            );
          }}
        </ReactTransition>
      </TransitionGroup>
    </React.Fragment>
  );
};

export default Transition;
