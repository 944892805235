import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import Header from "../components/MyHeader";
import Footer from "@components/Footer";
import GlobalFonts from "../fonts/fonts";
import LoadProvider from "../providers/LoadProvider";
import ResizeProvider from "../providers/ResizeProvider";
import Transition from "./transition";
import { calculatePadding } from "./page-nav-data";
import Loader from "@components/Loader";
import { IntlProvider } from "gatsby-plugin-intl";

const Layout = ({ children, location }) => {
  const [padding, setPadding] = useState([0, 0]);


  useEffect(() => {
    const _padding = calculatePadding({ pathname: location.pathname });
    setPadding(_padding);
  }, [location.pathname]);

  return (
    <LoadProvider>
      <ResizeProvider>
        <GlobalFonts />
        <Loader />
        <Header location={location} />
        <main className="pt-14 pb-0 z-0 relative lg:pb-18">
          <Transition location={location}>
            <div className="z-[200] relative pointer-events-none"
              css={css`
                  @media (min-width: 992px) {
                      padding-left: ${padding[0]}px;
                      padding-right: ${padding[1]}px;
                  }
              `}
            >
              <div className="pointer-events-auto overflow-hidden">
                {children}
              </div>
            </div>
            <div className="fixed top-0 left-0 h-full w-full p-4 flex items-center justify-center text-center bg-white z-[5000] hidden">
              <p>please see on desktop</p>
            </div>
          </Transition>
        </main>
        <Footer />
      </ResizeProvider>
    </LoadProvider>
  );
};

export default Layout;
