import React, { forwardRef, useRef, createRef, useEffect } from "react";
import { css } from "@emotion/react";
import { gsap } from "gsap/all";
import { items } from "../layouts/page-nav-data";
import Contacts from "@components/Contacts";
import Social from "@components/Social";
import { BLACK } from "@styles/colors";
import { Link } from "gatsby-plugin-intl";

const MenuItem = forwardRef((props, ref) => {

  const { title, url } = props;

  return (
    <Link
      ref={ref}
      to={url}
      css={css`
        height: 60px;
        max-height: 0px;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid black;
          background: white;
        `}
      >
        <span
          css={css`
            opacity: 0;
            font-family: "noah-heavy";
            font-size: 18px;
            text-transform: uppercase;
            color: ${BLACK};
          `}
        >
          {title}
        </span>
      </div>
    </Link>
  );
});

const Menu = ({ isOpen, location }) => {

  const itemRefs = useRef([]);
  const tlRef = useRef(null);
  const tlTitlesRef = useRef(null);

  if (itemRefs.current.length !== items.length) {
    itemRefs.current = Array(items.length)
      .fill()
      .map((_, i) => itemRefs.current[i] || createRef());
  }

  useEffect(() => {
    const divs = itemRefs.current.map(itemRef => itemRef.current);
    const titles = divs.map(div => div.querySelector("span"));

    tlRef.current = gsap.timeline({ paused: true }).to(divs, {
      maxHeight: 90,
      duration: 0.25,
      stagger: {
        amount: 0.25,
        ease: "power3.easeOut",
      },
    });
    tlTitlesRef.current = gsap.timeline({ paused: true }).to(titles, {
      opacity: 1,
      duration: 0.25,
      delay: 0.25,
      stagger: {
        amount: 0.25,
        ease: "power3.easeOut",
      },
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      tlRef.current.restart();
      tlTitlesRef.current.restart();
    } else {
      tlRef.current.reverse();
      tlTitlesRef.current.reverse();
    }
  }, [isOpen]);

  return (
    <nav
      css={css`
        position: fixed;
        top: 64px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: white;
        height: calc(100vh - 64px);

        transition: 0.25s all;
        opacity: ${isOpen ? "1" : "0"};
        transition-delay: ${isOpen ? "0s" : "0.8s"};
        pointer-events: ${isOpen ? "all" : "none"};
        @media (min-width: 992px) {
          display: none;
        }
      `}
    >
      {items.map((item, index) => (
        <MenuItem
          ref={itemRefs.current[index]}
          key={`item-mobile--${index}`}
          {...item}
        />
      ))}
      <div
        css={css`
          padding: 48px 24px;
          position: absolute;
          bottom: 0;
          width: 100%;
          opacity: ${isOpen ? "1" : "0"};
          transition-delay: ${isOpen ? "0.5s" : "0s"};
          transition: 0.5s all;
        `}
      >
        <Social />
        <Contacts />
      </div>
    </nav>
  );
};

export default Menu;
