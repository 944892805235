import React from "react";
import { changeLocale, useIntl } from "gatsby-plugin-intl";
import Icon from "./Icon";

const LanguageSwitcher = () => {

  const intl = useIntl();
  const locale = intl.locale;

  return (
    <span onClick={() => changeLocale(locale === 'de' ? 'en' : 'de')} className="cursor-pointer ml-8 text-sm">
      { locale === 'de' ? 'EN' : 'DE' }
    </span>
  );
};
export default LanguageSwitcher
